.logo {
    height: 60px;
    padding: 1px;
    background: white;
    /* border-bottom: 1px solid rgb(218, 218, 218); */
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.site-layout-sub-header-background {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px !important;
}

.sider-background {
    background-color: red !important;
}

.trigger {
    margin-inline: 16px;
    font-size: 24px;
    cursor: pointer;
    margin-top: -8px;
}

.site-layout-background {
    background: #fff;
}

[data-theme="dark"] .site-layout-sub-header-background {
    background: #141414;
}

.user-profile-text {
    line-height: 24px !important;
    text-align: right;
    text-transform: uppercase;
    color: #404040 !important;
}

.user-profile-status {
    font-size: x-small;
}

.user-profile-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
}

.user-profile-text-wrapper {
    display: flex;
    flex-direction: column;
    margin-inline: 16px;
}

.ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title>.ant-menu-title-content {
    flex: auto;
    min-width: 0;
    font-size: 12px !important;
    overflow: hidden;
    font-weight: bold !important;
    text-overflow: ellipsis;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: rgb(67 85 124 / 10%) !important;
}

/* .h-100 {
    background-color: #141414 !important;
    height: 100vh !important;
} */

.table-0 {
    width: 100%;
    padding: 0;
    margin-top: 5mm;
    border-bottom: 1px solid gray;
    border-left: 1px solid gray;
}

.table-0 th {
    text-align: center;
}

.table-0 th,
.table-0 td {
    font-size: 0.65rem;
    padding-inline: 0.19rem;
}

.table-0 th {
    border-block: 1px solid gray;
    background-color: #434343;
    padding: 0.23rem;
    color: white;
}

.table-0 tr:last-child {
    border-bottom: 0.01rem solid gray;
}

.table-0 tr:nth-child(even) {
    background-color: rgb(230, 230, 230);
}

.table-0 th {
    font-weight: bold;
}

.table-0 td:first-child,
.table-0 th:first-child {
    border-left: solid 1px gray;
}

.table-0 td:last-child,
.table-0 th:last-child {
    border-right: solid 1px gray;
}

/* partner */

.partner-item {
    padding: 90px 40px;
    border-radius: 5px;
    transition: 0.6s;
}

.partner-item:hover {
    cursor: pointer;
    box-shadow: 0 0 8px 1px rgb(225, 225, 225);
}

.partner-item img {
    width: 240px;
    height: 200px;
} 

/* footer */

.footer-top ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 250px;
    list-style: none;
}

.footer-top ul li {
    display: flex;
    align-items: center;
}

.footer-top svg {
    width: 26px;
    height: 26px;
    margin-right: 12px;
}

.footer-bottom {
    margin-top: 40px;
    padding: 0 5px;
    padding-top: 10px;
    text-align: center;
    border-top: 1px solid white;
}