.card-service p,
h4 {
    margin-bottom: 0;
    line-height: 1rem;
}

.wrapper {
    margin: 42px 8px;
    display: flex;
}

.card-custom {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    width: 95%;
}

.head-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    cursor: pointer;
    background-color: #f5f5f5;
    border-radius: 2px 2px 0px 0px;
    color: #444;

}

.body-card {
    padding: 16px 32px;
    background-color: white !important;
    /* border-top: 1px solid #9999; */
}

.detail-information {
    margin: 16px 0;
    background-color: white !important;
}

.text-bold {
    font-weight: 500;

}

.text-sub-title {
    font-size: 16px;
    font-weight: bold;
}

.head-item p {
    margin-bottom: 0;
}

.ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title>.ant-menu-title-content {
    flex: auto;
    min-width: 0;
    font-size: 14px !important;
    overflow: hidden;
    font-weight: bold !important;
    text-overflow: ellipsis;
}

textarea.ant-input {
    height: auto;
    font-size: 14px;
}
