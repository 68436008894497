.content p{
    margin-bottom: 0;
    color: black;
}

.text-title{
    font-weight: bold;
}

.table-print table tr td{
    border: 1px solid black;
}

.table-print td{
    width: 100px;
    padding: 4px;
}
