.banner {
    height: 500px;
    width: 100%;
    /* background-color: #0486c4 !important; */
    margin-top: 150px;
    text-align: center;
}

.timeline-solusi {
    padding: 100px;
}

.about-banner {
    height: 100vh;
    width: 100%;
    text-align: center;
}

.container-tracking {
    margin-top: 100px;
    height: 100vh;
    width: 100%;
}

/* solusi item section */

.solusi-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 370px;
    margin: 0 5px;
    text-align: center;
}

@media only screen and (max-width: 980px) {
    .banner-image {
        display: none !important;
    }

    .banner-title {
        width: 140% !important;
        margin-left: -60px;
    }

    .timeline-solusi {
        padding: 70px !important;
    }
}