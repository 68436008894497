.wrapper{
     display : flex;
     height: auto;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      padding : 32;
      flex-direction: column; 
      color: #dadada    
}

.wrapper .title-wrap{
    padding: 8px;
    color: #dadada 
}